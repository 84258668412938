<template>
  <student-form
    title="Alamat"
    :is-visible="isVisible"
    @update-triggered="triggerUpdate()"
  >
    <!-- form -->
    <form action="#" @submit.prevent="triggerUpdate()">
      <!-- Address -->
      <label class="form-label" for="address_addres">Alamat</label>
      <input type="text" id="address_address" v-model="form.address" />

      <!-- RT -->
      <label class="form-label" for="address_rt">RT</label>
      <input type="text" id="address_rt" v-model="form.rt" />

      <!-- RW -->
      <label class="form-label" for="address_rw">RW</label>
      <input type="text" id="address_rw" v-model="form.rw" />

      <!-- distric -->
      <span class="form-label">Kecamatan</span>
      <div
        v-for="(district, key) in choices.districts"
        :key="`district-${key}`"
        class="mb-1"
      >
        <input
          :id="'subdistrict-' + key"
          type="radio"
          name="subdistrict"
          :value="district"
          v-model="form.district"
        />
        <label
          class="ml-2 uppercase tracking-wider"
          :for="'subdistrict-' + key"
          >{{ district }}</label
        >
      </div>

      <!-- subdistrict -->
      <label class="mt-4 block form-label" for="address_subdistrict"
        >Desa/kelurahan</label
      >
      <input type="text" id="address_subdistrict" v-model="form.subdistrict" />

      <!-- live with -->
      <span class="mt-4 form-label">Tempat tinggal</span>
      <div
        class="mb-1.5"
        v-for="(home, key) in choices.liveWith"
        :key="`home-${key}`"
      >
        <input
          :id="'live-with-' + key"
          type="radio"
          name="live-with"
          :value="home"
          v-model="form.live_with"
        />
        <label
          class="ml-2 uppercase tracking-wider"
          :for="'live-with-' + key"
          >{{ home }}</label
        >
      </div>
      <input
        type="text"
        id="live-with-custom"
        placeholder="lainnya..."
        v-model="form.live_with"
      />

      <!-- distance from school -->
      <label class="form-label" for="address_distance"
        >jarak ke sekolah dari rumah (km)</label
      >
      <input
        type="text"
        id="address_distance"
        v-model="form.traveling_distance"
      />

      <!-- distance from school -->
      <label class="form-label" for="address_travel_time"
        >waktu tempuh ke sekolah (menit)</label
      >
      <input
        type="text"
        id="address_travel_time"
        v-model="form.traveling_time"
      />

      <!-- transportation -->
      <span class="form-label">moda transportasi</span>
      <div
        class="mb-1"
        v-for="(vehicle, key) in choices.transportation"
        :key="`transport-${key}`"
      >
        <input
          :id="'transportation-' + key"
          type="radio"
          name="transportation"
          :value="vehicle"
          v-model="form.vehicle"
        />
        <label class="uppercase wider ml-2" :for="'transportation-' + key">{{
          vehicle
        }}</label>
      </div>
      <input
        type="text"
        id="transportation-custom"
        placeholder="lainnya..."
        v-model="form.vehicle"
      />
    </form>
  </student-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _clone from "lodash/clone";

export default {
  data() {
    return {
      form: {},
      formDefault: {},
      choices: {
        districts: [
          "Pontianak Selatan",
          "Pontianak Timur",
          "Pontianak Barat",
          "Pontianak Tenggara",
          "Pontianak Kota",
          "Pontianak Utara",
          "Kuburaya",
          "Sei. Kakap",
          "Sei. Raya",
        ],
        liveWith: ["Orang Tua", "Wali", "Asrama", "Panti Asuhan"],
        transportation: [
          "Jalan Kaki",
          "Sepeda",
          "Sepeda Motor",
          "Ojek",
          "Mobil",
          "Mobil/Bus Antar Jemput",
          "Angkutan Umum",
        ],
      },
    };
  },
  methods: {
    async triggerUpdate() {
      await this.updateAddress(this.form);
    },

    ...mapActions("student", ["updateAddress"]),
  },
  // vm.form undefined?
  watch: {
    selected_form(val) {
      if (val !== "address") {
        this.form = _clone(this.formDefault);
        return;
      }

      this.form = _clone(this.selected_student.address);
    },
  },
  computed: {
    isVisible() {
      return this.form_visibility === true && this.selected_form === "address";
    },
    ...mapGetters("student", [
      "selected_form",
      "form_visibility",
      "selected_student",
    ]),
  },
  components: {
    StudentForm: () =>
      import("@/components/Student/InformationForm/StudentForm.vue"),
  },
  mounted() {
    this.formDefault = _clone(this.form);
  },
  name: "AddressForm",
};
</script>